import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`25:00 Ascending AMRAP of:`}</p>
    <p>{`5-Calorie Row`}</p>
    <p>{`5-Ring Dips`}</p>
    <p>{`5-Burpees`}</p>
    <p>{`:30 Plank `}<em parentName="p">{`(on elbows)`}</em></p>
    <p>{`10-Calorie Row`}</p>
    <p>{`10-Ring Dips`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`1:00 Plank`}</p>
    <p>{`15-Calorie Row`}</p>
    <p>{`15-Ring Dips`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`1:30 Plank`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`20-Ring Dips`}</p>
    <p>{`20-Burpees`}</p>
    <p>{`2:00 Plank`}</p>
    <p>{`25-Calorie Row`}</p>
    <p>{`25-Ring Dips`}</p>
    <p>{`25-Burpees`}</p>
    <p>{`2:30 Plank`}</p>
    <p>{`*`}{`If you complete this restart at 5, 5, 5, :30`}</p>
    <p>{`*`}{`:30 Plank = 1 Rep`}</p>
    <p>{`*`}{`Score = Total Rounds + Reps or Total Reps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      